.highlightCell {
  background-color: #edffdd;
}

.text-blue {
  color: blue !important;
}

.text-green {
  color: green !important;
}

.ag-theme-quartz.large,
.ag-theme-quartz-dark.large {
  --ag-grid-size: 10px;
  --ag-list-item-height: 40px;
  --ag-font-size: 32px;
}

.ag-theme-quartz.compact,
.ag-theme-quartz-dark.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
}
.pointer-cursor {
  cursor: pointer;
}